import { FirebaseError } from "firebase/app";
import { CeqaDocumentTypeChoices, GeoPointFragment, GetMeDocument } from "~/gql/graphql";
import { useMainStore } from "~/store";

export type EmploymentType = "agencyEmployment" | "firmEmployment" | "principalOwnerEmployment" | "student";

export type BBox = {
    topLeft: { lat: number; lon: number };
    bottomRight: { lat: number; lon: number };
};

// middleware/auth.ts


export interface DropdownOption<T> {
    id: string;
    optionString: string;
    value: T;
}

export function dbeDisplay(dbeCategoriesToShow: string[]) {
    if (dbeCategoriesToShow.length === 0) {
        return ''
    }
    return capitalizeFirstLetter(dbeCategoriesToShow.map((db) => db.toLowerCase()).join(' ')) + ' owner'
}

export function optionsForEnum(
    enumType: Record<string, string>,
    renderer?: (v: any) => string,
): DropdownOption<string>[] {
    return Object.entries(enumType).map(([key, value]) => ({
        id: key,
        selectedString: renderer
            ? renderer(key)
            : toTitleCase(value.replaceAll("_", " ")),
        optionString: renderer
            ? renderer(key)
            : toTitleCase(value.replaceAll("_", " ")),
        value: value,
    }));
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const compareArrays = (a: any[], b: any[]) => {
    return a.length === b.length && a.every((v, i) => v === b[i]);
};

export function enumStringToTitleCase(value: string) {
    return toTitleCase(value.replaceAll("_", " "));
}

export function createDebounce(): (fnc: any, delayMs?: number) => void {
    let timeout: any = null;
    return function (fnc: any, delayMs?: number) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fnc();
        }, delayMs || 300);
    };
}

export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function highlightsToHTML(searchResult: any) {
    let result = "";
    for (const highlight of searchResult.highlights)
        result += `<span class="text-gray-400">${highlight.fieldName}: </span> <span class="">${highlight.html.replaceAll("<p>", " ").replaceAll("</p>", " ")}</span>&nbsp;`;

    return result;
}

export function baseColorForParty(party: string) {
    party = party.toLowerCase();
    switch (party) {
        case "republican":
            return "red";
        case "democrat":
        case "democratic":
            return "blue";
        case "independent":
            return "green";
        default:
            return "green";
    }
}

export function colorForParty(party: string) {
    party = party.toLowerCase();
    switch (party) {
        case "republican":
            return "bg-red-700";
        case "democrat":
        case "democratic":
            return "bg-blue-700";
        case "independent":
            return "bg-green-700";
        default:
            return "bg-gray-500";
    }
}

export function textColorForParty(party: string) {
    party = party.toLowerCase();
    switch (party) {
        case "republican":
            return "text-red-700";
        case "democrat":
        case "democratic":
            return "text-blue-700";
        case "independent":
            return "text-gray-800";
        default:
            return "text-gray-800";
    }
}

export function groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K) {
    return arr.reduce(
        (groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        },
        {} as Record<K, T[]>,
    );
}

export function getFirebaseErrorMessage(reason: FirebaseError): string {
    let errorMessage = "There was an error logging in. Please try again later.";
    switch (reason.code) {
        case "auth/weak-password":
            errorMessage =
                "Your password is too weak. Please use a stronger password.";
            break;
        case "auth/invalid-email":
            errorMessage = "Your email address is invalid. Please try again.";
            break;
        case "auth/email-already-in-use":
            errorMessage =
                "Email address is already in use on a different account. Please log in or use a different email address.";
            break;
        case "auth/invalid-credential":
            errorMessage = "Your email address is invalid";
            break;
        case "auth/user-disabled":
            errorMessage =
                "Your account has been disabled. Please contact support.";
            break;
        case "auth/user-not-found":
            errorMessage =
                "No account found with that email address. Please check the spelling or sign up for an account if you haven't already done so.";
            break;
        case "auth/wrong-password":
            errorMessage = "Wrong password. Please try again.";
            break;
        case "auth/too-many-requests":
            errorMessage = "Too many requests";
            break;
        case "auth/operation-not-allowed":
            errorMessage = "Operation not allowed";
            break;
        case "auth/invalid-action-code":
            errorMessage =
                "This login link is invalid or expired. Please request a new one.";
            break;
    }
    console.log(reason.code, errorMessage);
    return errorMessage;
}

export function getUnabbreviatedCEQADocumentType(
    documentType: CeqaDocumentTypeChoices,
): string {
    switch (documentType) {
        case CeqaDocumentTypeChoices.Adm:
            return "Addendum";
        case CeqaDocumentTypeChoices.Bia:
            return "Tribal Notice of Decision";
        case CeqaDocumentTypeChoices.Bla:
            return "Bureau of Indian Affairs Notice of Land Acquisition";
        case CeqaDocumentTypeChoices.Con:
            return "Early Consultation";
        case CeqaDocumentTypeChoices.Ea:
            return "Environmental Assessment";
        case CeqaDocumentTypeChoices.Eir:
            return "Draft EIR";
        case CeqaDocumentTypeChoices.Eis:
            return "Draft EIS";
        case CeqaDocumentTypeChoices.Fed:
            return "Functional Equivalent Document";
        case CeqaDocumentTypeChoices.Fin:
            return "Final Document";
        case CeqaDocumentTypeChoices.Fis:
            return "Final Environmental Statement";
        case CeqaDocumentTypeChoices.Fon:
            return "FONSI - Findings of No Significant Impact";
        case CeqaDocumentTypeChoices.Fot:
            return "Federal Other Document";
        case CeqaDocumentTypeChoices.Fyi:
            return "Information Only";
        case CeqaDocumentTypeChoices.Jd:
            return "Joint Document";
        case CeqaDocumentTypeChoices.Mea:
            return "Master Environmental Assessment";
        case CeqaDocumentTypeChoices.Mnd:
            return "Mitigated Negative Declaration";
        case CeqaDocumentTypeChoices.Nde:
            return "Notice of Decision";
        case CeqaDocumentTypeChoices.Nod:
            return "Notice of Determination";
        case CeqaDocumentTypeChoices.Noe:
            return "Notice of Exemption";
        case CeqaDocumentTypeChoices.Neg:
            return "Negative Declaration";
        case CeqaDocumentTypeChoices.Noc:
            return "Other Notice of Completion";
        case CeqaDocumentTypeChoices.Noi:
            return "Notice of Intent";
        case CeqaDocumentTypeChoices.Nop:
            return "Notice of Preparation of a Draft EIR";
        case CeqaDocumentTypeChoices.Oth:
            return "Other Document";
        case CeqaDocumentTypeChoices.Ran:
            return "Request for Advanced Notification";
        case CeqaDocumentTypeChoices.Rc:
            return "Response to Comments";
        case CeqaDocumentTypeChoices.Rev:
            return "Revised";
        case CeqaDocumentTypeChoices.Rir:
            return "Revised Environmental Impact Rep";
        case CeqaDocumentTypeChoices.Rod:
            return "Record of Decision";
        case CeqaDocumentTypeChoices.Sbe:
            return "Subsequent EIR";
        case CeqaDocumentTypeChoices.Sca:
            return "Sustainable Communities Environmental Assessment";
        case CeqaDocumentTypeChoices.Sea:
            return "SEA - Supplemental EIR";
        case CeqaDocumentTypeChoices.Sir:
            return "Supplemental EIR";
        case CeqaDocumentTypeChoices.Sis:
            return "Revised/Supplemental EIS";
        case CeqaDocumentTypeChoices.Tri:
            return "Tribal Environmental Evaluation";
    }
}

export function bboxFromQueryString(
    bboxQueryString: string | undefined,
): BBox | undefined {
    if (!bboxQueryString) return undefined;
    return {
        topLeft: {
            lat: parseFloat(bboxQueryString.split(",")[0]),
            lon: parseFloat(bboxQueryString.split(",")[1]),
        },
        bottomRight: {
            lat: parseFloat(bboxQueryString.split(",")[2]),
            lon: parseFloat(bboxQueryString.split(",")[3]),
        },
    };
}

import { filesize } from "filesize";
import moment from "moment";

export function humanFileSize(kbSize: number) {
    return filesize(kbSize * 1024, { standard: "jedec" });
}

export function renderDate(date: Date | null) {
    if (!date) return "";
    return moment(date).format("MMM DD, YYYY");
}

export function renderDateAndTime(date: Date | null) {
    if (!date) return "";
    return moment(date).format("MMM DD, YYYY hh:mm A");
}

export function renderProjectTimespan(
    startDate: Date | null,
    endDate: Date | null,
) {
    if (!startDate) return "";
    if (!endDate || endDate === startDate)
        return `${moment(startDate).format("MMM DD, YYYY")}`;
    return `${moment(startDate).format("MMM DD, YYYY")} - ${moment(
        endDate,
    ).format("MMM DD, YYYY")}`;
}


export function renderTimeSpan(range: {
    startMonth?: number | null;
    startYear?: number | null;
    endMonth?: number | null;
    endYear?: number | null;
}) {
    const start =
        range.startYear && range.startMonth
            ? `${getMonthName(range.startMonth)} ${range.startYear}`
            : range.startYear
              ? `${range.startYear}`
              : null;
    const end =
        range.endMonth && range.endYear
            ? `${getMonthName(range.endMonth)} ${range.endYear}`
            : range.endYear
              ? `${range.endYear}`
              : null;
    if (start && !end) return start + " - Present";
    if (!start && end) return end;
    if (!start && !end) return "";
    return `${start} - ${end}`;
}

function getMonthName(month: number) {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    return months[month - 1];
}



export function getLinkedinHandleForUrl(value: string, assumeType?: string): string | null {
    if (!value) {
        return null;
    }
    let parts: string[] = [];
    try {
        const url = new URL(value);
        if (url.hostname === 'www.linkedin.com') {
            parts = url.pathname.split('/').filter(part => part);
        }
    } catch (e) {
        parts = value.split('/').filter(part => part);
    }
    for (let i = 0; i < parts.length; i++) {
        if (['company', 'in'].includes(parts[i])) {
            if (i + 1 < parts.length) {
                return parts.slice(i, i + 2).join('/');
            }
        }
    }
    if (parts.length === 1 && assumeType) {
        return [assumeType, parts[0]].join('/');
    }
    return null;
}

const DEFAULT_HOME_PAGE = "/enviro/home";

export function getRedirectUrl(redirect: string | string[] | undefined): string {
    if (!redirect) {
        return DEFAULT_HOME_PAGE;
    }
    if (Array.isArray(redirect)) {
        redirect = redirect[0];
    }
    if (redirect.indexOf("login") !== -1) {
        redirect = DEFAULT_HOME_PAGE;
    } else if (redirect.indexOf("join") !== -1) {
        redirect = DEFAULT_HOME_PAGE;
    }
    return redirect;
}

export function getAdminUrl(modelClass: string, id: string) {
 return `${(useRuntimeConfig().public.apiBaseUrl as string).slice(0, -4)}/admin/api/${modelClass}/${id}/change/`;
}


export function getMarkerForPoint(
    geoPoint: GeoPointFragment,
    glyph: string,
    title?: string,
): google.maps.marker.AdvancedMarkerElement {
    const pinElement = new google.maps.marker.PinElement({
        background: "#f97316",
        borderColor: "#FFFFFF",
        glyphColor: "#FFFFFF",
        glyph: glyph,
        scale: 1,
    });
    const marker = new google.maps.marker.AdvancedMarkerElement({
        position: new google.maps.LatLng({
            lat: geoPoint.lat!,
            lng: geoPoint.lon!,
        }),
        content: pinElement.element,
        gmpClickable: true,
        title: title,
    });

    return marker;
}
